import { createGlobalStyle } from "styled-components";
import "../../static/fonts/fonts.css";

export const GlobalStyle = createGlobalStyle` 
   *,
     ::after,
     ::before {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
     }
:root {
    --white: #FFFFFF;
    --black: #0A0A0A
}
&& body {
    font-family: 'Inter', sans-serif;
    font-weight:400 ;
    color: var(--black);
    background-color:var(--black) ;
  a {
      text-decoration: none ;
  }
   
}
`;
